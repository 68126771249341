import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import indicatorsService from "../services/indicators.service";

const { vnHome: viewName, vfVerCampanasActivasSalientes: viewFunction } =
  viewTypes;
const userName = localStorage.getItem("userName");

// Fetch campaign
const fetchIndicator = async (filter) => {
  const response = await indicatorsService.obtenerEncuestasActivas(
    filter,
    userName,
    viewName,
    viewFunction
  );

  return response.data;
};

export function useFetchIndicator(filter) {
  return useQuery({
    queryKey: ["indicators", filter],
    queryFn: () => fetchIndicator(filter),
    retry: false,
    refetchOnWindowFocus: false,
  });
}

/** */
