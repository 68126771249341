import React, { useEffect, useState } from "react";
import { NowSaliente } from "./NowSaliente";
import { NowEntrante } from "./NowEntrante";
import { useRootStore } from "../store/rootStore";

const CampaignNow = () => {
  const { viewTypes, showView } = useRootStore((state) => state);

  const {
    vnHome: viewName,
    vfVerCampanasActivasSalientes,
    vfVerCampanasActivasEntrantes,
  } = viewTypes;

  const showActivasSalientes = showView(
    vfVerCampanasActivasSalientes,
    viewName
  );
  const showActivasEntrantes = showView(
    vfVerCampanasActivasEntrantes,
    viewName
  );

  return (
    <div className="campaign-now">
      {showActivasSalientes && <NowSaliente />}
      {showActivasEntrantes && <NowEntrante />}
    </div>
  );
};

export default CampaignNow;
