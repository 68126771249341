import http from "../common/components/http-common";
var qs = require("qs");

export const obtenerCampaigns = (
  filters,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
    params: filters,
    paramsSerializer: (p) => {
      return qs.stringify(p, { arrayFormat: "brackets" });
    },
  };
  return http.get("/v2/campaign/", config);
};

export const ObtenerCampaignById = (
  id,
  type,
  userName: string,
  viewName: string,
  viewFunction: string
) => {
  let config = {
    headers: {
      userName: userName,
      viewName: viewName,
      viewFunction: viewFunction,
    },
  };
  return http.get<any>(`/v1/campaign/${type}/${id}`, config);
};
