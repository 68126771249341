import React from "react";
import backgroundImage from "../image/background.jpg";

const WelcomeComponent = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    width: "100vw",
    height: "calc(100vh - 80px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "3rem",
    padding: "0 16px",
  };

  const userName = localStorage.getItem("userName");

  return (
    <div style={backgroundStyle}>
      <h2>
        Bienvenido <strong>{userName}</strong>
      </h2>
    </div>
  );
};

export default WelcomeComponent;
