import http from "../common/components/http-common";

class IndicatorsService {
  obtenerEncuestasActivas(
    filter,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let parameter = {
      user: filter.userId,
      excludedStatus: "4",
      type: filter.type,
      esWebCliente: true,
      permitirVista: filter.permitirVista,
      // ver estoo:
      // pageSize: filter.pageSize,
      // pageNumber: filter.pageNumber,
    };
    let config = {
      headers: {
        pageSize: filter.pageSize,
        pageNumber: filter.pageNumber,
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: parameter,
    };

    return http.get<any>("/encuestas/indicators/", config);
  }
}

export default new IndicatorsService();
