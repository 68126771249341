import { useQuery } from "@tanstack/react-query";
import { getCampaignAmount } from "../services/amount.service";

// Fetch campaign amount
const fetchCampaignAmount = async (id, userName, viewName, viewFunction) => {
  const response = await getCampaignAmount(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaignAmount(id, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["camount", id],
    queryFn: () => fetchCampaignAmount(id, userName, viewName, viewFunction),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
