import { useQuery } from "@tanstack/react-query";
import {
  ObtenerCampaignById,
  obtenerCampaigns,
} from "../services/campaign.service";

// Fetch campaign
const fetchCampaign = async (filter, userName, viewName, viewFunction) => {
  const response = await obtenerCampaigns(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaign(filter, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["campaign", filter],
    queryFn: () => fetchCampaign(filter, userName, viewName, viewFunction),
  });
}

// Fetch campaign by id
const fetchCampaignById = async (
  id,
  type,
  userName,
  viewName,
  viewFunction
) => {
  const response = await ObtenerCampaignById(
    id,
    type,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaignById(
  id,
  type,
  userName,
  viewName,
  viewFunction
) {
  return useQuery({
    queryKey: ["camp", id],
    queryFn: () =>
      fetchCampaignById(id, type, userName, viewName, viewFunction),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
