import { useQuery } from "@tanstack/react-query";
import { viewTypes } from "../common/types/views.type";
import {
  ObtenerCampaignById,
  obtenerCampaigns,
} from "../services/campaign.service";

const { vnHome: viewName, vfVerCampanasFinalizadas: viewFunction } = viewTypes;
const userName = localStorage.getItem("userName");

// Fetch campaign
const fetchCampaign = async (filter) => {
  const response = await obtenerCampaigns(
    filter,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaign(filter) {
  return useQuery({
    queryKey: ["campaign", filter],
    queryFn: () => fetchCampaign(filter),
  });
}

// Fetch campaign by id
const fetchCampaignById = async (id, type) => {
  const response = await ObtenerCampaignById(
    id,
    type,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaignById(id, type) {
  return useQuery({
    queryKey: ["camp", id],
    queryFn: () => fetchCampaignById(id, type),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
