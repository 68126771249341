import React from "react";
import CampaingNow from "./CampaignNow";
import CampaingPast from "./CampaingPast";
import WelcomeComponent from "./WelcomeComponent";
import { useRootStore } from "../store/rootStore";

export const HomeRoot = () => {
  const { viewTypes, showView } = useRootStore((state) => state);

  const {
    vnHome: viewName,
    vfVerCampanasActivasSalientes,
    vfVerCampanasActivasEntrantes,
    vfVerCampanasFinalizadas,
  } = viewTypes;

  const showFinalizadas = showView(vfVerCampanasFinalizadas, viewName);
  const showActivasSalientes = showView(
    vfVerCampanasActivasSalientes,
    viewName
  );
  const showActivasEntrantes = showView(
    vfVerCampanasActivasEntrantes,
    viewName
  );
  const showWelcome =
    !showActivasSalientes && !showActivasEntrantes && !showFinalizadas;

  return (
    <div className="grid">
      {(showActivasSalientes || showActivasEntrantes) && (
        <div className="col-12 xl:col-12" style={{ padding: 0 }}>
          <CampaingNow />
        </div>
      )}
      {showFinalizadas && (
        <div className="col-12 xl:col-12">
          <CampaingPast />
        </div>
      )}
      {showWelcome && <WelcomeComponent />}
    </div>
  );
};
