import { useQuery } from "@tanstack/react-query";
import indicatorsService from "../services/indicators.service";

// Fetch campaign
const fetchIndicator = async (filter, userName, viewName, viewFunction) => {
  const response = await indicatorsService.obtenerEncuestasActivas(
    filter,
    userName,
    viewName,
    viewFunction
  );

  return response.data;
};

export function useFetchIndicator(filter, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["indicators", filter],
    queryFn: () => fetchIndicator(filter, userName, viewName, viewFunction),
    retry: false,
    refetchOnWindowFocus: false,
  });
}

/** */
