import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

export const FilterHomeComponent = ({
  setLazyParams,
  restarDias,
  defaultCantidadDias,
}) => {
  const periodos = [
    {
      label: "15 días",
      value: 15,
    },
    {
      label: "30 días",
      value: 30,
    },
    {
      label: "90 días",
      value: 90,
    },
  ];

  const [selectedPeriodo, setSelectedPeriodo] = useState(defaultCantidadDias);

  const handlePeriodo = (data) => {
    setSelectedPeriodo(data);

    setLazyParams((previousState) => {
      return {
        ...previousState,
        fechaInicioDesde: restarDias(data),
        fechaInicioHasta: restarDias(0),
      };
    });
  };

  return (
    <div>
      <SelectButton
        className="select-periodo"
        value={selectedPeriodo}
        options={periodos}
        onChange={(e) => handlePeriodo(e.value)}
      />
    </div>
  );
};
