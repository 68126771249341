import { useQuery } from "@tanstack/react-query";
import { getCampaignDuration } from "../services/duration.service";

// Fetch campaign duration
const fetchCampaignDuration = async (id, userName, viewName, viewFunction) => {
  const response = await getCampaignDuration(
    id,
    userName,
    viewName,
    viewFunction
  );
  return response.data;
};

export function useFetchCampaignDuration(id, userName, viewName, viewFunction) {
  return useQuery({
    queryKey: ["cdurat", id],
    queryFn: () => fetchCampaignDuration(id, userName, viewName, viewFunction),
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  });
}
/** */
