import React, { useState, useEffect } from "react";
import AuthDataService from "./common/services/auth.service";
import ConfigEnviroment from "./config/ConfigEnviroment";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { HomeRoot } from "./components/HomeRoot";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useRootStore } from "./store/rootStore";

export default function Root(props) {
  const { viewTypes, showView } = props;
  const {
    viewTypes: vTypes,
    setViewTypes,
    setShowView,
  } = useRootStore((state) => state);

  useEffect(() => {
    setViewTypes(viewTypes);
    setShowView(showView);
  }, [viewTypes]);

  const queryClient = new QueryClient();

  useEffect(() => {
    if (props.name === "@froneus/evi-home") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <div className="layout-dashboard evi-home">
      <QueryClientProvider client={queryClient}>
        {vTypes && setShowView && <HomeRoot />}
      </QueryClientProvider>
    </div>
  );
}
